import $ from 'jquery';
window.jQuery = $;
require('@fancyapps/fancybox/dist/jquery.fancybox');


(function($) {
  $('[data-fancybox]').fancybox({
    smallBtn: false
  });
  
  /**
   * Funzione d'appoggio
   * Richiamata con il parametro da cercare
   */
  function getQueryString(key){
    key = key.replace(/[*+?^$.\[\]{}()|\\\/]/g, "\\$&"); // escape RegEx meta chars
    var match = location.search.match(new RegExp("[?&]"+key+"=([^&]+)(&|$)"));
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
  }
  
  var qs = getQueryString("video");
  if(qs >= 1) {
    $("#video-"+qs).click();
  }
  console.log(qs);
  
}( $ ));